.nav-tabs {
  border: none;
  background: #fff;
  list-style: none;
  padding: 0;
  white-space: nowrap;
  margin: 0;
  width: 100%;
  padding-top: 0;
  box-shadow: inset 0 -2px 0 0 #e6edf1;

  li a {
    border: none;
    border-radius: 0;
    font-size: rem(14px);
    padding: 5px;
    position: relative;

    &:hover {
      background-color: transparent;
    }
  }

  &.darken {
    box-shadow: inset 0 -2px 0 0 darken(#eef5f9, 6%);
  }
}

.nav-tabs.nav-justified>li>a {
  margin-bottom: 0;
}

.nav-tabs li.active>a {
  color: #3e4c5e;
}

.nav-tabs li a:after {
  content: "";
  left: 0;
  height: 2px;
  position: absolute;
  width: 100%;
  bottom: -3px;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.nav-tabs li.active a:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.nav-tabs.nav-tabs-right {
  text-align: right;
}

.nav-tabs.nav-justified>li {
  display: table-cell;
  width: 1%;
  text-align: center;
  float: none;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
  color: #516073;
  cursor: default;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  border-bottom-color: transparent !important;
}

.nav-tabs.alt-tabs {
  background: transparent;

  li a:after {
    bottom: 1px;
  }

  li>a {
    color: rgba(255, 255, 255, .7);
    font-size: rem(14px);
  }

  li.active>a,
  li.active>a:focus,
  li.active>a:hover {
    color: #fff;
    cursor: default;
    background-color: transparent !important;
    border: 1px solid transparent !important;
    border-bottom-color: transparent !important;
  }
}

.nav-tabs.nav-tabs-right {
  text-align: right;

  li {
    display: inline-block;
    float: none;
  }
}

.nav-pills {
  .nav-link {
    padding: .8rem 1.3em;
  }
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent !important;
}

//Tabs justified
.nav-tabs.nav-justified>.active>a,
.nav-tabs.nav-justified>li>a {
  font-size: rem(14px);
  //padding: 21px 0;
}

.nav-tabs.nav-justified>.active>a,
.nav-tabs.nav-justified>.active>a:focus,
.nav-tabs.nav-justified>.active>a:hover,
.nav-tabs.nav-justified>li>a {
  border: none;
}