 @import "_colors.scss";

 // .right {
 //     display: inline-block;
 //     vertical-align: top;
 //     max-width: 575px;
 //     padding-left: 10px;
 //     font-size: 14px;
 //     font-weight: 600
 // }

 // .left {
 //     width: 197px;
 //     display: inline-block;
 //     //font-weight: 600;
 //     font-size: 14px;
 // }

 .block {
   display: block;
 }

 .flex {
   display: flex;
 }

 .flex-wrap {
   flex-wrap: wrap;
 }

 .flex-end {
   display: flex;
   justify-content: flex-end;
 }

 .f-left {
   float: left;
 }

 .f-right {
   float: right;
 }

 .loader-wrp {
   height: 80% !important;
   background: #fafafa !important;
 }

 .card-title {
   float: left;
 }

 .pdd-tp {
   padding-top: 30px;
 }

 .pdd-btm-tp {
   padding-bottom: 0;
   padding-top: 0px;
 }

 .mrg {
   margin: 0 20px 25px 20px;
 }

 .mrg-lft {
   margin-left: 17px;
 }

 .m-t-18 {
   margin-top: 18px;
 }

 .m-l-17 {
   margin-left: 17px;
 }

 .m-l-18 {
   margin-left: 18px;
 }

 .m-b-17 {
   margin-bottom: 17px;
 }

 .m-r-75 {
   margin-right: 75px !important;
 }

 .note-desc {
   color: #ec407a;
 }

 .p-0-15 {
   padding: 0px 15px !important;
 }

 .p-0-20 {
   padding: 0px 20px;
 }

 .p-0-25 {
   padding: 0px 25px;
 }

 .p-info-field {
   padding: 10px 20px 20px 20px;
 }

 .pdd-side {
   padding: 0.5rem 2rem;
 }

 .card-btn {
   float: right;
   font-size: 1.25rem;
   color: #688696;
   cursor: pointer;
   padding-right: 34px;
   padding-top: 20px;
 }

 .cursor {
   cursor: pointer;
 }

 .note-title {
   padding: 0 20px;
   font-size: 17px;
   margin-bottom: 0;
 }

 /* formu elementai */
 .mat-mdc-form-field {
   width: 100%;
 }

 /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
 /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
 .mat-form-field-underline {
   background-color: rgba(0, 0, 0, 0.12);
 }

 /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
 /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
 .mat-select-underline {
   background-color: rgba(0, 0, 0, 0.12);
 }

 .input-element {
   input {
     font: inherit;
     background: 0 0;
     color: currentColor;
     border: none;
     outline: 0;
     padding: 0;
     width: 100%;
     vertical-align: bottom;
     border-bottom: 1px solid rgba(0, 0, 0, .12);
     padding-bottom: 10px;
     padding-top: 10px;
     //font-weight: 700;
     font-size: 0.875rem;
   }
 }

 .zmdi-caret-down {
   color: $color-black !important;
   font-size: 23px;
   display: -webkit-box;
   float: right;
   margin-right: 5px;
   height: 0;
 }



 proto-input-field ::placeholder {
   color: $color-black !important;
 }

 upload-file ::placeholder {
   color: $color-black !important;
 }

 #row-selector {

   //  float: right;
   //  margin-top: -3px;
   //  width: 60px;
   /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
   /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
   .mat-select-trigger {
     min-width: 50px;
   }
 }

 .circle-opt {
   font-weight: bold;
   cursor: pointer;

   .zmdi {
     font-size: 28px !important;
     text-align: center;
     width: 100%;
     display: block;
   }
 }

 .check-opt {
   float: left;

   /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
   /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
   .mat-mdc-checkbox-inner-container {
     height: 14px;
     width: 14px;
     margin-right: 5px;
   }

   label {
     margin-bottom: 0px;
     padding-top: 8px;
     margin-right: 5px;
   }
 }

 .filter-field {
   width: 100%;
   padding-left: 5px;
   padding-top: 5px;
 }

 .action-button {
   min-width: 20px;
   margin-right: 5px;
   margin-top: 7px;
   float: left;

   a {
     color: $action-button-color;
   }

   .zmdi {
     font-size: 37px !important;
   }
 }

 .action-button:hover {
   .zmdi {
     color: $action-buttom-hover-color;
   }
 }

 .text-ico {
   i {
     font-size: 22px;
   }
 }

 .money-ico {
   font-size: 22px !important;
   color: #7d7d7d;
 }

 .money-ico.zmdi-money {
   color: #2fc236;
 }

 .underline {
   text-decoration: underline;
 }

 .mail {
   color: #3c6382 !important;

   &:hover {
     color: #ec407a !important;
     text-decoration: underline;
   }
 }

 @media (min-width: 1269px) {
   .col-md-7-custom {
     -ms-flex: 0 0 58.333333%;
     flex: 0 0 58.333333%;
     max-width: 58.333333%;
   }
 }

 .col-md-7-custom {
   position: relative;
   width: 100%;
   min-height: 1px;
   padding-right: 15px;
   padding-left: 15px;
 }

 @media (min-width: 1269px) {
   .col-md-5-custom {
     -ms-flex: 0 0 41.666667%;
     flex: 0 0 41.666667%;
     max-width: 41.666667%;
   }
 }

 .col-md-5-custom {
   position: relative;
   width: 100%;
   min-height: 1px;
   padding-right: 15px;
   padding-left: 15px;
 }

 .jobs-buttons-field {
   min-height: 45px;
   width: 100%;
   padding: 10px;
   background: #fafafa;
   border-bottom: 1px solid rgba(0, 0, 0, 0.10);

   div {
     min-width: 0px;
     float: left;
     margin-left: 10px;
   }

   div a {
     font-size: 16px;
     text-align: center;
     display: flex;
     margin-right: 10px;
     cursor: pointer;
   }

   div a i {
     font-size: 28px;
     margin-right: 5px;
   }

   div a span {
     margin-top: 4px;
   }
 }

 .header-bar {
   display: flex;
   flex-wrap: wrap;

   >div {
     margin: 5px;
   }
 }