.mat-mdc-tooltip {
  background: darken(#9daec4, 10%) !important;
  font-size: rem(13px) !important;
}

.popover {
  max-width: 300px;
  box-shadow: 0 5px 10px rgba(175, 188, 206, .6);
  border: 1px solid rgba(175, 188, 206, .3);

  .popover-title {
    background-color: #eef5f9;
    border-bottom: none;
    font-weight: 400;
    font-size: 16px;
    padding: 15px 10px;
  }

  .popover-content {
    padding: 9px 14px;
  }
}

//Demo only styles
.btn-equal-width .btn {
  width: 40%;
}