.ql-formats button,
.ql-formats .ql-background.ql-picker,
.ql-formats .ql-align,
.ql-formats .ql-font.ql-picker,
.ql-formats .ql-color.ql-picker {
  position: relative;

  &:hover::after,
  &:active::after,
  &:focus::after {
    position: absolute;
    left: -50%;
    top: -40px;
    width: max-content;
    z-index: 1;
    background: rgba(80, 80, 80, 0.9);
    font-size: 14px;
    color: #fff;
    margin: 14px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    font-size: 0.8125rem;
    animation: mat-mdc-tooltip-show 150ms cubic-bezier(0, 0, 0.2, 1) forwards;
    line-height: 16px;
    padding: 4px 4px !important;
    min-width: 40px;
    max-width: 200px;
    min-height: 24px;
    max-height: 40vh;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;

  }
}

.ql-formats .ql-font.ql-picker {

  &:hover::after,
  &:active::after,
  &:focus::after {
    left: 0 !important;
  }
}

.ql-bold {

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Paryškintasis";
  }
}

.ql-italic {

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Pasvirasis";
  }
}

.ql-font {

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Šriftas";
  }
}

.ql-underline {

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Pabraukti";
  }
}

.ql-strike {

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Perbraukti";
  }
}

.ql-list[value="bullet"] {

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Suženklintasis sąrašas";
  }
}

.ql-list[value="ordered"] {

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Sunumeruotasis sąrašas";
  }
}

.ql-indent[value="-1"] {

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Sumažinti įtrauką";
  }
}

.ql-indent[value="+1"] {

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Padidinti įtrauką";
  }
}

.ql-align {

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Lygiuotė";
  }
}

.ql-color.ql-picker {

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Teksto spalva";
  }
}

.ql-background.ql-picker {

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Paryškinimo spalva";
  }
}

.ql-clean {

  &:hover::after,
  &:active::after,
  &:focus::after {
    content: 'Išvalyti formatavimą';
  }
}

.quill-container ol,
.quill-container ul {
  li {
    font-size: 0.9375rem;
  }
}

.quill-container p {
  white-space: unset;
  text-align: unset;
}