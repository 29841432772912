///// MaterialWrap - Angular 4 Seed Project
////////////////////////////////////////////////////
//== Vendor

@import "../node_modules/multi-brand-colors/dist/scss/_index.scss";

@import "../node_modules/mdi/css/materialdesignicons.css";
@import "../node_modules/material-design-iconic-font/dist/css/material-design-iconic-font.css";

//== App
// @import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "./app/template/shared/styles/app.scss";

.advanced-pie-legend .legend-items-container .legend-items {
  overflow: initial !important;
}

.advanced-pie-legend {
  line-height: 27px !important;
}

.advanced-pie {
  width: 200px;
}

.total-value {
  font-size: 19px !important;
}

.total-label {
  font-size: 20px !important;
}

.item-value {
  font-size: 18px !important;
}

.item-percent {
  font-size: 19px !important;
}

.bar {
  width: 5px;
}