.search-input-position {
  @media only screen and (max-width:1470px) {
    width: 55% !important;
  }

  @media only screen and (max-width:1350px) {
    width: 50% !important;
  }

  @media only screen and (max-width:1250px) {
    width: 45% !important;
  }

  @media only screen and (max-width:1155px) {
    width: 40% !important;
  }
}

.item-row {
  min-height: 40px !important;
  width: 500px !important;

  @media only screen and (max-width:1710px) {
    width: 470px !important;
  }

  @media only screen and (max-width:1650px) {
    width: 430px !important;
  }

  @media only screen and (max-width:1600px) {
    width: 340px !important;
  }

  @media only screen and (max-width:1500px) {
    width: 190px !important;
  }
}

@mixin twelve-inch ($bp:"<=1269") {
  @media only screen and (max-width: 1269px) {
    @content;
  }
}

@mixin thirteen-inch($bp:"<=1330") {
  @media only screen and (max-width: 1310px) {
    @content;
  }
}

@mixin fourteen-inch ($bp:"<=1416") {
  @media only screen and (max-width: 1416px) {
    @content;
  }
}

@mixin fifteen-inch ($bp:"<=1515") {
  @media only screen and (max-width: 1515px) {
    @content
  }
}

@mixin sixteen-inch ($bp:"<=1620") {
  @media only screen and (max-width: 1620px) {
    @content
  }
}

@mixin sixteen-inch2 ($bp:"<=1550") {
  @media only screen and (max-width: 1550px) {
    @content
  }
}

@mixin seventeen-inch ($bp:"<=1667") {
  @media only secreen and (max-width: 1667px) {
    @content;
  }
}