/*
$color - viso apskritimo spalva
$border-color - "judancios dalies" spalva
$circle-size - bendras apskritimo dydis
$check-size - varneles storis
*/

@mixin loader-loading($color : #fff, $border-color : rgba(0, 0, 0, 0.2), $circle-size : 20px, $check-size : 1px) {

  $brand-success: $color;
  $loader-size: $circle-size;
  $check-height: calc($loader-size/2);
  $check-width: calc($check-height/2);
  $check-left: calc($loader-size/6 + $loader-size/12);
  $check-thickness: $check-size;
  $check-color: $brand-success;
  $error-color: #FF0000;

  .circle-loader.loading {
    margin-bottom: calc($loader-size/2);
    border: 1px solid $border-color;
    border-left-color: $check-color;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
  }

  .circle-loader.complete {
    margin-bottom: calc($loader-size/2);
    border: 1px solid #fff;
    //border-left-color: #fff
    animation: offAnim 0s ease-in 5s forwards;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
  }

  .complete {
    -webkit-animation: none;
    animation: none;
    border-color: $check-color;
    transition: border 0ms ease-out;

    .checkmark {
      display: block;
    }
  }

  .checkmark {
    display: none;

    &.draw:after {
      animation-duration: 800ms;
      animation-timing-function: ease;
      animation-name: checkmark;
      transform: scaleX(-1) rotate(135deg);
      margin-left: -1px;
    }

    &:after {
      opacity: 1;
      height: $check-height;
      width: $check-width;
      transform-origin: left top;
      border-right: $check-thickness solid $check-color;
      border-top: $check-thickness solid $check-color;
      content: '';
      left: $check-left;
      top: $check-height;
      position: absolute;
    }
  }

  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }

    20% {
      height: 0;
      width: $check-width;
      opacity: 1;
    }

    40% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }

    100% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
  }

  @keyframes offAnim {
    to {
      width: 0px;
      height: 0px;
      opacity: 0;
    }
  }
}