// Sriftu spalva 
$color-black: black;
// Iconu spalvos 
$star-color: #28bebd;
$play-circle-color: #00c853;
$assignment-circle-color: #8d6e63;
$account-circle-color: #9daec4;
// Activity log'o splavos'
$activity-box-color: white;
$activity-box-border-color: #dee5e7;
$activity-box-hover-color: #f0f3f4;
$activity-log-line: #dee5e7;
$activity-log-header: #1c2732;
$activity-log-time-color: #8b9eb3;

$activity-log-commented-color: #3C6382;
$activity-log-executor-color: #60a3bc;
$activity-log-finished-color: #60a3bc;
$activity-log-watcher-color: #33755a;
$activity-log-state-changed-color: #E8C82C;
$activity-log-disqualified-color: #5AB691;
$activity-log-transfered-color: #54d870;
$activity-log-job-entered-color: #82ccdd;

// Mygtuku spalvos 
// Uzklausu ir Formu peziuros mygtukai
$action-button-color: #009688 !important;
$transfer-task-btn-color: #222f3c;
$edit-task-btn-color: #222f3c;
$more-btn-color: #222f3c !important;
$add-job-btn-color: #222f3c !important;
$take-request-btn-color: #222f3c;
$update-task-state-btn-color: #222f3c;
$transfer-request-btn-color: #222f3c;
$update-request-state-btn-color: #222f3c;
$request-for-group-btn-color: #222f3c !important;
/// Uzklausu, Formu mygtuku hover'iu spalvos 
//trasfer,edit, more, uptade, add-job, trasfer, request for group
$btn-hover-color: #b0bec5 !important;

$take-request-hover-color: #b0bec5 !important;
$action-buttom-hover-color: #80cbc4;

$red-mat-error: #f44336;

$dashboard-task-color: #4dd189;
$dashboard-request-color: #debb21;