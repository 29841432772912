.three-bar-loader {
  animation-delay: -0.15s;
  color: #9daec4;
}

.three-bar-loader,
.three-bar-loader:before,
.three-bar-loader:after {
  background: #9daec4;
  animation: threeBarLoad 1s infinite ease-in-out;
  width: 3px;
  height: 12px;
}

.three-bar-loader:before,
.three-bar-loader:after {
  position: absolute;
  content: '';
}

.three-bar-loader:before {
  left: -6px;
  animation-delay: -0.3s;
}

.three-bar-loader:after {
  left: 6px;
}

@keyframes threeBarLoad {

  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 6px;
  }

  40% {
    box-shadow: 0 -6px;
    height: 14px;
  }
}