.column-btn-text {
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  // font-size: large;
  // width: 100%;
}

.column-btn-check {
  // float: left;
  // height: 22px !important;
  width: 100%;

}

.white-checkbox {

  .mdc-form-field {
    color: white !important;
  }

  .mdc-checkbox__background {
    border-color: white !important;
    background-color: transparent !important;
  }

}


.adjust-columns {
  margin: 0;
  padding: 5px;
  background: #9daec4;
  color: #ffffff;
  font-size: 0.875rem;
  text-transform: none;
  width: 100%;
  margin-bottom: 5px;
}

.custom-modal .mat-mdc-dialog-container {
  padding-bottom: 0px;
}

bs-dropdown-container {
  z-index: 100;
}

.mat-drawer.mat-drawer-side.mat-drawer-opened:not(.ng-animating) {
  transform: translate3d(0px, 0px, 0px) !important;
  overflow-x: hidden;
}

.files-dropdown-menu-align-fix {
  top: 100% !important;
  left: -40px !important;
}

.leftSidenav2-wrapper .sidenav-container {
  min-height: 654px;
  // min-height: calc(100vh - 65px);

}

.text-badge {
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 700;
}

.state-badge {
  padding: 2px 7px !important;
  border-radius: 3px !important;
}

.cdk-overlay-container {
  //tooltip shown under files dropdown <files-dropdown> without this fix
  z-index: 10000 !important;
}

.swal2-container {
  z-index: 10001 !important;
}

.violation-chart-dropdown-menu-align-fix.dropdown-menu li a:hover {
  background-color: #9daec4;
  border-radius: 5px;
}

.violation-chart-dropdown-menu-align-fix {
  top: 100% !important;
  left: -20px !important;
}

.top-align-fix {
  top: 100% !important;
}

.z-index-fix {
  z-index: 100;
}

.limit-dropdown-height-fix {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
}

@media (max-width: 959.99px) {
  .limit-dropdown-height-fix {
    max-height: 200px !important;
  }
}

.violation-chart-dropdown-menu-width-fix {
  min-width: 280px;
}

.dashboard-cards-loader-wrapper {
  margin-top: 120px;
  margin-bottom: 50px;

  #loader {
    border: 3px solid transparent;
    border-top-color: #28BEBD;
    border-radius: 50%;
    display: block;
    margin: -75px 0 0 -75px;
    width: 80px;
    height: 80px;
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
}

@media (max-width: 959.99px) {
  .mat-mdc-dialog-container {
    max-width: 100% !important;
  }

  .cdk-overlay-pane {
    max-width: 100% !important;
  }
}

.pointer {
  cursor: pointer;
}

.request-bg-color {
  background-color: #22A7D6 !important;
}

.task-bg-color {
  // background-color: #22D6A3 !important;
  background-color: #EC407A !important;
}

.tab-title {
  letter-spacing: 1.25px !important;
  font-size: 16px !important;
}

.mdc-tab {
  background-color: white !important;
}

.dt-checkbox-fix .mdc-checkbox {
  box-sizing: content-box !important;
}

.w600 {
  width: 600px;
}

// .editor-container .ql-editor {
//   resize: vertical;
//   overflow-y: scroll;
// }

.editor-container .ql-editor {
  min-height: 120px;
}

.editor-container .ql-container {
  font-family: unset;
}

// .ql-container {
//   resize: vertical;
//   overflow-y: scroll;
// }

.center {
  text-align: center !important;
}

.table-ico {
  font-size: 22px !important;
  color: #7d7d7d;
}

.table-icon {
  font-size: 22px;
  padding-left: 5px;
  color: #3c6382;

  &:hover {
    color: #ec407a;
  }
}

// $text-editor-fonts: 'monospace';

// @each $text-editor-font in $text-editor-fonts {
//   .ql-font-#{$text-editor-font} {
//     font-family: $text-editor-font;
//   }
// }

.today-date {
  color: #42a5f5;
}

.outdated-date {
  color: #c23333;
}

.dropdown-menu-no-wrap li {
  white-space: nowrap;
}

.dropdown-menu-limit-width {
  min-width: 260px;
}

.quill-description-container .ql-toolbar {
  border-radius: 5px 5px 0 0;
}

.quill-description-container .ql-container {
  border-radius: 0 0 5px 5px;
  min-height: 112px;
}

.ql-container {
  font-size: 17px;
}

.ql-editor.ql-blank::before {
  font-style: normal;
}

.mh80vh {
  max-height: 80vh;
}

.oxa {
  overflow-x: auto;
}