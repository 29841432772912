	.ngx-datatable .datatable-body .datatable-row-wrapper {
	  cursor: pointer;
	}

	.ngx-datatable.material {
	  overflow-x: hidden !important;
	}

	.progress-linear {
	  .container {
	    z-index: 1;
	  }
	}

	.clr-light-green {
	  color: #28bebd;
	}

	.zmdi-plus-circle {
	  font-size: 20px;
	}

	#row-selector {
	  // float: right;
	  // margin-right: 40px;
	}

	.row-selector {
	  width: 100px;
	  padding: 10px;
	}

	.ngx-datatable.material .datatable-footer .page-count {
	  line-height: 25px;
	  height: 25px;
	  font-size: 15px;
	}

	.ngx-datatable.material .datatable-header .datatable-header-cell .datatable-header-cell-label {
	  font-size: 14px;
	}

	.ngx-datatable.material .datatable-header {
	  height: 40px !important;
	}

	.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
	  padding: 7px 10px 5px 10px;
	}

	.ngx-datatable.material .datatable-header .datatable-header-cell {
	  padding: 5px 10px;
	}

	.mat-drawer {
	  width: 790px;
	}

	#app_wrapper #content_outer_wrapper .content_inner_wrapper #content_wrapper .content {
	  padding-bottom: 0 !important;
	  min-height: 0 !important;
	}

	.mat-drawer-transition .mat-drawer-content {
	  overflow-x: hidden;
	  min-height: calc(100vh - 65px); //top menu height 65px
	}

	.datatable-body {
	  overflow-y: hidden;
	}

	.sidebar-label {
	  padding: 15px 15px !important;
	  font-size: .75rem !important;
	  color: #797979;
	  font-weight: 600;
	  margin-bottom: 0;
	}

	.pdd {
	  padding-top: 2px;
	}

	.card-add-note {
	  max-width: 575px;
	  margin: 30px auto;
	}

	.brick {
	  padding: 1em;
	  width: 100%;
	  min-width: 0;
	  position: relative;
	  transition: .2s ease-in-out;

	  @media screen and (min-width: 468px) {
	    width: 50%;
	  }

	  @media screen and (min-width: 768px) {
	    width: 33.33%;
	  }

	  @media screen and (min-width: 1440px) {
	    width: 25%;
	  }

	  .card.card-note {
	    margin: 0;
	  }
	}

	//Header & Search
	.header-wrapper {
	  h1 {
	    display: inline-block;
	  }

	  .header-search-form {
	    display: block;
	    // width: 250px;

	    .header-search-icon {
	      position: absolute;
	      z-index: 15;
	      right: 15px;
	      top: 6px;

	      i {
	        color: #0F6D6C !important;
	        font-size: em(20);
	      }
	    }

	    .form-control {
	      background-image: none;
	      // margin: 12px 0 0;
	      // padding: 10px 20px;
	      border-radius: 3px;
	      // height: 45px;
	      display: inline-block;
	      color: #fff;
	      -webkit-transition: all 150ms ease-in-out;
	      -khtml-transition: all 150ms ease-in-out;
	      -moz-transition: all 150ms ease-in-out;
	      -ms-transition: all 150ms ease-in-out;
	      -o-transition: all 150ms ease-in-out;
	      transition: all 150ms ease-in-out;
	      width: 100%;
	      //text-indent: 30px;
	      background-color: rgba(255, 255, 255, .2);
	      border: none;
	    }

	    .form-group {
	      .form-control:focus {
	        background-color: #fff;
	        // color: #4b5a6d;
	        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14),
	          0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .2);
	        opacity: 1;

	        .header-search-icon {
	          i {
	            color: #4b5a6d !important;
	          }
	        }
	      }

	      ::-webkit-input-placeholder {
	        color: rgba(255, 255, 255, 1) !important;
	      }

	      :-moz-placeholder {
	        color: rgba(255, 255, 255, 1) !important;
	      }

	      ::-moz-placeholder {
	        color: rgba(255, 255, 255, 1) !important;
	      }

	      :-ms-input-placeholder {
	        color: rgba(255, 255, 255, 1) !important;
	      }

	      input:focus::-webkit-input-placeholder {
	        color: #4b5a6d !important;
	      }

	      input:focus:-moz-placeholder {
	        color: #4b5a6d !important;
	      }

	      input:focus::-moz-placeholder {
	        color: #4b5a6d !important;
	      }

	      input:focus:-ms-input-placeholder {
	        color: #4b5a6d !important;
	      }
	    }
	  }

	  .form-group.is-focused {
	    .header-search-icon {
	      i {
	        color: #4b5a6d !important;
	      }
	    }
	  }

	  @media (max-width: 767px) {
	    .header-search-form {
	      // display: none;
	    }
	  }
	}


	///////////////////////////////////
	.blurred-text {
	  opacity: 0.5;
	}

	.nowrap {
	  white-space: nowrap;
	}

	.icon-badge::after {
	  content: '!';
	  font-weight: 1000;
	  color: red;
	  font-size: 14px;
	}

	@media (max-width: 959.99px) {
	  .mat-drawer {
	    width: 100% !important;
	  }
	}